<template>
 <div>
    <b-modal
      id="CashOnDeliveryModal"
      modal-class="modal-primary"
      :title="$t('Cash On Delivery')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Cash On Delivery-logo.png" height="150" /></p>
        <p>{{$t('Make the Cash On Delivery available to your customers that prefer this method')}}</p>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.savePaymentService(config, 'CashOnDeliveryModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('COD Fee')"
          label-for="v-cod-fee"
        >
          <b-input-group>
            <b-form-input
              id="v-cod-fee"
              type="number"
              v-model="config.price"
            />
            <b-input-group-append is-text>
              {{$t('SAR')}}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
      }
      if (Object.keys(this.config).length == 0) {
        this.config.id = this.$parent.paymentMethodsIds.COD
      }
      this.$bvModal.show("CashOnDeliveryModal")
    }
  }
}
</script>