<template>
 <div>
    <b-modal
      id="TapApplePayModal"
      modal-class="modal-primary"
      :title="$t('Tap | Apple Pay')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tap - Apple Pay-logo.png" height="130" /></p>
        <p>{{$t('TAP Payment provides you with e-payment service via Mada, Visa, MasterCard, as well as Apple Pay')}}</p>
        <!-- <b-button
          variant="success"
          size="sm"
          class="mb-1"
          v-if="Object.keys($parent.$refs.tapModal.config).length == 0"
        >
          {{$t('Packages & Fill Form')}}
        </b-button>
        <p 
          class="text-danger"
          v-if="Object.keys($parent.$refs.tapModal.config).length > 0 && Object.keys(config).length == 0"
        >
          {{$t('Contact with Sellers Serive to active Apple Pay')}}
        </p> -->
        <hr>
      </div>
      <b-form @submit.prevent="$parent.savePaymentService(config, 'TapApplePayModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          :label="$t('merchant identifier')"
          label-for="v-merchant_identifier"
        >
          <b-form-input
            id="v-merchant_identifier"
            type="text"
            v-model="config.merchant_identifier"
          />
        </b-form-group>

        <b-form-group
          :label="$t('crt file path')"
          label-for="v-crt_file"
        >
          <b-form-input
            id="v-crt_file"
            type="text"
            v-model="config.crt_file"
          />
        </b-form-group>

        <b-form-group
          :label="$t('key file path')"
          label-for="v-key_file"
        >
          <b-form-input
            id="v-key_file"
            type="text"
            v-model="config.key_file"
          />
        </b-form-group>

        <b-form-group
          :label="$t('password')"
          label-for="v-password"
        >
          <b-form-input
            id="v-key_file"
            type="text"
            v-model="config.password"
          />
        </b-form-group>
        
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.config.is_active = this.config.is_active == "1" || this.config.is_active == true ? true : false
      }
      if (Object.keys(this.config).length == 0) {
        this.config.id = this.$parent.paymentMethodsIds.TAPAPPLEPAY
      }
      this.$bvModal.show("TapApplePayModal")
    }
  }
}
</script>