<template>
  <div>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Cash On Delivery-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.COD).is_active == true"
              />
              {{$t('Cash On Delivery')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.cashOnDeliveryModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Bank Transfer-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.BANKTRANSFER).is_active == true"
              />
              {{$t('Bank Transfer')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.bankTransferModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Moyasar-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.MOYASAR).is_active == true"
              />
              {{$t('Moyasar')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.moyasarModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Moyasar - Apple Pay-logo.png" height="70" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.MOYASARAPPLEPAY).is_active == true"
              />
              {{$t('Moyasar | Apple Pay')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.moyasarApplePayModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Moyasar - STC Pay-logo.png" height="70" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.MOYASARSTCPAY).is_active == true"
              />
              {{$t('Moyasar | STC Pay')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.moyasarStcPayModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tap-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.TAP).is_active == true"
              />
              {{$t('Tap')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tapModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tap - Apple Pay-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.TAPAPPLEPAY).is_active == true"
              />
              {{$t('Tap | Apple Pay')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tapApplePayModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col> -->
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tabby-logo.png" height="60" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.TABBYINSTALLMENTS).is_active == true"
              />
              {{$t('Tabby | Installments')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tabbyInstallmentsModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tabby-logo.png" height="60" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.TABBYPAYLATER).is_active == true"
              />
              {{$t('Tabby | Pay Later')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tabbyPayLaterModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Tamara-logo.png" height="60" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getPaymentMethodById(paymentMethodsIds.TAMARAINSTALLMENTS).is_active == true"
              />
              {{$t('Tamara | Installments')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.tamaraInstallmentsModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- Cash On Delivery -->
    <cash-on-delivery-modal ref="cashOnDeliveryModal" :config="getPaymentMethodById(paymentMethodsIds.COD)"></cash-on-delivery-modal>
    <!-- Bank Transfer -->
    <bank-transfer-modal ref="bankTransferModal" :config="getPaymentMethodById(paymentMethodsIds.BANKTRANSFER)"></bank-transfer-modal>
    <!-- Moyasar -->
    <moyasar-modal ref="moyasarModal" :config="getPaymentMethodById(paymentMethodsIds.MOYASAR)"></moyasar-modal>
    <!-- Moyasar Apple Pay -->
    <moyasar-apple-pay-modal ref="moyasarApplePayModal" :config="getPaymentMethodById(paymentMethodsIds.MOYASARAPPLEPAY)"></moyasar-apple-pay-modal>
    <!-- Moyasar STC Pay -->
    <moyasar-stc-pay-modal ref="moyasarStcPayModal" :config="getPaymentMethodById(paymentMethodsIds.MOYASARSTCPAY)"></moyasar-stc-pay-modal>
    <!-- Tap -->
    <tap-modal ref="tapModal" :config="getPaymentMethodById(paymentMethodsIds.TAP)"></tap-modal>
    <!-- Tap | Apple Pay -->
    <tap-apple-pay-modal ref="tapApplePayModal" :config="getPaymentMethodById(paymentMethodsIds.TAPAPPLEPAY)"></tap-apple-pay-modal>
    <!-- Tabby | Installments -->
    <tabby-installments-modal ref="tabbyInstallmentsModal" :config="getPaymentMethodById(paymentMethodsIds.TABBYINSTALLMENTS)"></tabby-installments-modal>
    <!-- Tabby | Pay Later -->
    <tabby-pay-later-modal ref="tabbyPayLaterModal" :config="getPaymentMethodById(paymentMethodsIds.TABBYPAYLATER)"></tabby-pay-later-modal>
    <!-- Tamara | Installments -->
    <tamara-installments-modal ref="tamaraInstallmentsModal" :config="getPaymentMethodById(paymentMethodsIds.TAMARAINSTALLMENTS)"></tamara-installments-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import CashOnDeliveryModal from './payment-services/CashOnDeliveryModal.vue'
import BankTransferModal from './payment-services/BankTransferModal.vue'
import MoyasarModal from './payment-services/MoyasarModal.vue'
import MoyasarApplePayModal from './payment-services/MoyasarApplePayModal.vue'
import MoyasarStcPayModal from './payment-services/MoyasarStcPayModal.vue'
import TapModal from './payment-services/TapModal.vue'
import TapApplePayModal from './payment-services/TapApplePayModal.vue'
import TabbyInstallmentsModal from './payment-services/TabbyInstallmentsModal.vue'
import TabbyPayLaterModal from './payment-services/TabbyPayLaterModal.vue'
import TamaraInstallmentsModal from './payment-services/TamaraInstallmentsModal.vue'

export default {
  name: 'PaymentMethods',
  components: {
    CashOnDeliveryModal,
    BankTransferModal,
    MoyasarModal,
    MoyasarApplePayModal,
    MoyasarStcPayModal,
    TapModal,
    TapApplePayModal,
    TabbyInstallmentsModal,
    TabbyPayLaterModal,
    TamaraInstallmentsModal,
  },
  data() {
    return {
      payment_methods_config: {},
      isLoading: false,
      isLoadingSave: false,
      paymentMethodsIds: {
        COD: 1,
        BANKTRANSFER: 2,
        MOYASAR: 3,
        TAP: 4,
        TAPAPPLEPAY: 5,
        TABBYINSTALLMENTS: 7,
        TABBYPAYLATER: 8,
        MOYASARAPPLEPAY: 9,
        TAMARAINSTALLMENTS: 10,
        MOYASARSTCPAY: 11,
      }
    }
  },
  mounted() {
    this.getPaymentMethods();
  },
  methods: {
    getPaymentMethods(){
      this.isLoading = true
      useJwt.post('/store/config/get-by-id',{key: 'payment_methods_config'})
      .then((response) => {
        // console.log(response.data)
        this.payment_methods_config = response.data.data.payment_methods_config.payment_methods_active
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    getPaymentMethodById(id) {
      if (Object.keys(this.payment_methods_config).length) {
        let paymentMethod = this.payment_methods_config.find(method => { return method.id == id })
        if (paymentMethod) {
          return paymentMethod;
        }
      }
      return {};
    },
    savePaymentService(config, modalName) {
      this.isLoadingSave = true;
      config.store_id = this.$store.state.store.id;
      useJwt.post('/store/config/payment-methods/save',config)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.getPaymentMethods()
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide(modalName)
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    }
  }
}
</script>
