<template>
 <div>
    <b-modal
      id="MoyasarStcPayModal"
      modal-class="modal-primary"
      :title="$t('Moyasar | STC Pay')"
      hide-footer
      centered
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Moyasar - STC Pay-logo.png" height="150" /></p>
        <p>{{$t('Moyasar Payment provides you with e-payment service via Mada, Visa, MasterCard, as well as Apple Pay')}}</p>
        <b-button
          variant="success"
          size="sm"
          class="mb-1"
          v-if="Object.keys(config).length == 0"
          to="/payment-services/moyasar-form"
        >
          {{$t('Packages & Fill Form')}}
          <feather-icon icon="ChevronsLeftIcon" />
        </b-button>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.savePaymentService(config, 'MoyasarStcPayModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('Public Key')"
          label-for="v-public-key"
        >
          <b-form-input
            id="v-public-key"
            type="text"
            v-model="config.public_key"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Secret Key')"
          label-for="v-public-key"
        >
          <b-form-input
            id="v-secret-key"
            type="text"
            v-model="config.secret_key"
          />
        </b-form-group>
        
        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  methods: {
    openModal() {
      if (Object.keys(this.config).length == 0) {
        this.config.id = this.$parent.paymentMethodsIds.MOYASARSTCPAY
      }
      this.$bvModal.show("MoyasarStcPayModal")
    }
  }
}
</script>